export default {
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 👈 Ritorna al generatore"])},
  "calc": {
    "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla di seguito che tutte le emoji del sondaggio siano state analizzate correttamente e che siano separate da una virgola. Correggerli se necessario."])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incolla le risposte."])},
    "parser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla di seguito che le emoji del sondaggio siano state riconosciute correttamente e che siano separate da una virgola. \nCorreggile se necessario."])}
  },
  "content": {
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esegui il conteggio del sondaggio. È sufficiente incollare di seguito il testo di tutte le risposte dei tuoi amici."])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea il testo del tuo EmojiPoll e dì addio ai tradizionali sondaggi via link! Basta inserire le opzioni possibili e condividere il testo sulla tua app di messaggi preferita. Una volta che ti avranno risposto (usando emoji) potrai calcolare l'esito del sondaggio con il link sopra! "])},
    "catchphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per cosa vuoi creare un sondaggio?"])}
  },
  "core": {
    "card": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora puoi copiarlo e/o condividerlo con chiunque. Puoi poi conteggiare le risposte incollandole nella <a class='link' href='/calc'>pagina apposita.</a>"])},
      "copy-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia il testo"])},
      "share-whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condividi su WhatsApp"])},
      "show-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra il testo generato"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testo EmojiPoll generato!"])}
    },
    "input": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inserisci"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi una nuova data ✏️🦄"])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("num")), " selezionate. Clicca per aggiungere nuove date."])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi una nuova opzione ✏️🦄"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il titolo del sondaggio (facoltativo)"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi opzioni di tempo (facoltativo, separato da una virgola)"])}
    },
    "mode": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per trovare una data"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per trovare un'opzione"])}
    },
    "table": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scelte dell'EmojiPoll"])}
    },
    "text": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispondi a questo messaggio con l'emoji che corrisponde alla tua scelta:\n\n"])},
      "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generato con emojipoll.jacksalici.com"])}
    },
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Già fatto un sondaggio? \nCalcola il risultato!"])}
  },
  "menu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["💁‍♂️ About"])},
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["📊 Calcolatore"])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🏡 Generatore"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🌐 Lingue"])},
    "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🧰 Toolbox"])}
  },
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
  "title": {
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcola il risultato!"])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genera sondaggi basati su emoji!"])}
  },
  "wip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🧑‍💻 L'app è ancora in lavorazione."])},
  "settings": {
    "regenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["♻️ Rigenera tutte le emoji"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⚙️ Impostazioni"])}
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
  "input": {
    "ph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il mio sondaggio preferito 🗳"])},
    "ph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h18🌅,h21🌃"])}
  }
}