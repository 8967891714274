export default {
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 👈 Volver al generador"])},
  "calc": {
    "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique a continuación que todos los emoji de la encuesta se hayan analizado correctamente y separados por una coma. Corríjalos si es necesario."])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pega las respuestas."])},
    "parser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifique a continuación que todos los emoji de la encuesta se hayan analizado correctamente y separados por una coma. \nCorríjalos si es necesario."])}
  },
  "content": {
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecutar el conteo de la encuesta. Simplemente pegue debajo de todas las respuestas de texto de sus compañeros."])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Cree su texto EmojiPoll y diga adiós a las encuestas de enlaces tradicionales! \nSimplemente ingrese las opciones posibles y comparta el texto en su aplicación de mensajería favorita. \nUna vez que te hayan respondido (con emoji), ¡puedes calcular el resultado de la encuesta con el enlace de arriba!"])},
    "catchphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Para qué quieres crear una encuesta?"])}
  },
  "core": {
    "card": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora puede copiarlo y/o compartirlo con cualquier persona. Luego, puede analizar las respuestas y pegarlas en <a class='link' href='/calc'>la página específica.</a>"])},
      "copy-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copia el texto"])},
      "share-whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir en WhatsApp"])},
      "show-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar el texto generado"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EmojiEncuesta de texto generada!"])}
    },
    "input": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega una nueva fecha ✏️🦄"])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("num")), " seleccionado. Haga clic para agregar nuevas fechas."])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega una nueva opción ✏️🦄"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserta el título de la encuesta (opcional)"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar opciones de tiempo (opcional, separados por una coma)"])}
    },
    "mode": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentra una fecha"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentra una opción"])}
    },
    "table": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EmojiOpciones de encuesta"])}
    },
    "text": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responda a este mensaje con el emoji que coincida con su elección:\n\n"])},
      "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generado con emojipoll.jacksalici.com"])}
    },
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya hiciste una encuesta? \n¡Comprueba el resultado!"])}
  },
  "menu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["💁‍♂️ Acerca de"])},
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["📊 Calculadora"])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🏡 Generador"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🌐 Idiomas"])},
    "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🧰 Toolbox"])}
  },
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De acuerdo"])},
  "title": {
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Calcula el resultado!"])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Genera encuestas basadas en emojis!"])}
  },
  "wip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🧑‍💻 La aplicación aún está en proceso."])},
  "settings": {
    "regenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["♻️ Regenerar todos los emoji"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⚙️ Configuración"])}
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
  "input": {
    "ph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi encuesta favorita 🗳"])},
    "ph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h18🌅,h21🌃"])}
  }
}