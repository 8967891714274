export default {
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 👈 Retournez au générateur"])},
  "calc": {
    "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier ci-dessous que tous les emoji du sondage ont été analysés correctement et séparés par une virgule. Corrigez-les si nécessaire."])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collez les réponses."])},
    "parser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier ci-dessous que tous les emoji du sondage ont été analysés correctement et séparés par une virgule. \nCorrigez-les si nécessaire."])}
  },
  "content": {
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exécuter le décompte du scrutin. Collez simplement ci-dessous toutes les réponses textuelles de vos collègues."])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre texte EmojiPoll et dites adieu aux sondages traditionnels sur les liens ! \nEntrez simplement les options possibles et partagez le texte sur votre application de messagerie préférée. \nUne fois qu'ils vous ont répondu (avec emoji), vous pouvez calculer le résultat de l'enquête avec le lien ci-dessus !"])},
    "catchphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour quoi voulez-vous créer un sondage ?"])}
  },
  "core": {
    "card": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant le copier et/ou le partager avec n'importe qui. Ensuite, vous pouvez analyser les réponses en les collant dans <a class='link' href='/calc'>la page spécifique.</a>"])},
      "copy-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiez le texte"])},
      "share-whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager sur Whatsapp"])},
      "show-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le texte généré"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte EmojiPoll généré !"])}
    },
    "input": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle date ✏️🦄"])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("num")), " sélectionné. Cliquez pour ajouter de nouvelles dates."])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une nouvelle option ✏️🦄"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insérez le titre du sondage (facultatif)"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des options de temps (facultatif, séparé par une virgule)"])}
    },
    "mode": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver un rendez-vous"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver une option"])}
    },
    "table": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix d'EmojiPoll"])}
    },
    "text": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de répondre à ce message avec l'emoji correspondant à votre choix :\n\n"])},
      "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Généré avec emojipoll.jacksalici.com"])}
    },
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déjà fait un sondage ? \nVérifiez le résultat !"])}
  },
  "menu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["💁‍♂️ À propos"])},
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["📊 Calculatrice"])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🏡 Générateur"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🌐 Langues"])},
    "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🧰 Toolbox"])}
  },
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'accord"])},
  "title": {
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculez le résultat!"])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générez des sondages basés sur les emoji!"])}
  },
  "wip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🧑‍💻 L'application est toujours en cours de développement."])},
  "settings": {
    "regenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["♻️ Régénérer tous les emoji"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⚙️ Paramètres"])}
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["proche"])},
  "input": {
    "ph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon sondage préféré 🗳"])},
    "ph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h18🌅,h21🌃"])}
  }
}