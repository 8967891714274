export default {
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 👈 Go back to the generator"])},
  "calc": {
    "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check below that all the poll's emoji had been parsed correctly and separated by a comma. Correct them if necessary."])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste the answers."])},
    "parser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check below that all the poll's emoji had been parsed correctly and separated by a comma. Correct them if necessary."])}
  },
  "content": {
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute the tally of the poll. Just paste below all your fellows text answers."])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your EmojiPoll text and say goodbye to traditional link polls! Just enter the possible options and share the text on your favorite messaging app. Once they have answered you (with emoji), you can calculate the outcome of the survey with the link above!"])},
    "catchphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" What do you want to create a poll for?"])}
  },
  "core": {
    "card": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now copy it and/or share it with anyone. Then, you can parse the answers pasting them in <a class='link' href='/calc'>the specific page.</a>"])},
      "copy-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the text"])},
      "share-whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share on WhatsApp"])},
      "show-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show the generated text"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EmojiPoll text generated!"])}
    },
    "input": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new date ✏️🦄"])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("num")), " selected. Click to add new dates."])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new option ✏️🦄"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insert the poll's title (optional)"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add time options (optional, separated by a comma)"])}
    },
    "mode": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To find a date"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To find an option"])}
    },
    "table": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EmojiPoll choices"])}
    },
    "text": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please answer to this message with the emoji that matches your choice:\n\n"])},
      "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated with emojipoll.jacksalici.com"])}
    },
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already made a poll? Check the result!"])}
  },
  "menu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["💁‍♂️ About"])},
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["📊 Calculator"])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🏡 Generator"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🌐 Languages"])},
    "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🧰 Toolbox"])}
  },
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "title": {
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate the outcome!"])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate emoji-based polls!"])}
  },
  "wip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🧑‍💻 The app is still work in progress."])},
  "settings": {
    "regenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["♻️ Regenerate all emoji"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⚙️ Settings"])}
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "input": {
    "ph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My favourite poll 🗳"])},
    "ph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h18🌅,h21🌃"])}
  }
}