export default {
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 👈 Geh zurück zum Generator"])},
  "calc": {
    "helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie unten, ob alle Emojis der Umfrage korrekt geparst und durch ein Komma getrennt wurden. Korrigieren Sie sie gegebenenfalls."])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie die Antworten ein."])},
    "parser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie unten, ob alle Emojis der Umfrage korrekt geparst und durch ein Komma getrennt wurden. \nKorrigieren Sie sie gegebenenfalls."])}
  },
  "content": {
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führen Sie die Auszählung der Umfrage durch. Fügen Sie einfach unter allen Textantworten Ihrer Kollegen ein."])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Ihren EmojiPoll-Text und verabschieden Sie sich von traditionellen Link-Umfragen! \nGeben Sie einfach die möglichen Optionen ein und teilen Sie den Text in Ihrer bevorzugten Messaging-App. \nSobald sie Ihnen geantwortet haben (mit Emoji), können Sie das Ergebnis der Umfrage mit dem obigen Link berechnen!"])},
    "catchphrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wofür möchten Sie eine Umfrage erstellen?"])}
  },
  "core": {
    "card": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können es jetzt kopieren und/oder mit anderen teilen. Anschließend können Sie die Antworten parsen, indem Sie sie in <a class='link' href='/calc'>der jeweiligen Seite einfügen.</a>"])},
      "copy-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren Sie den Text"])},
      "share-whatsapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf WhatsApp teilen"])},
      "show-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen Sie den generierten Text an"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EmojiPoll-Text generiert!"])}
    },
    "input": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Datum hinzufügen ✏️🦄"])},
      "format": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("num")), " ausgewählt. Klicken Sie hier, um neue Daten hinzuzufügen."])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge eine neue Option hinzu ✏️🦄"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie den Titel der Umfrage ein (optional)"])},
      "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitoptionen hinzufügen (optional, durch Komma getrennt)"])}
    },
    "mode": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finden Sie ein Datum"])},
      "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finden Sie eine Option"])}
    },
    "table": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EmojiPoll-Auswahlmöglichkeiten"])}
    },
    "text": {
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte antworten Sie auf diese Nachricht mit dem Emoji, das Ihrer Wahl entspricht:\n\n"])},
      "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt mit emojipoll.jacksalici.com"])}
    },
    "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schon eine Umfrage gemacht? \nÜberprüfen Sie das Ergebnis!"])}
  },
  "menu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["💁‍♂️ Über"])},
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["📊 Rechner"])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🏡Generator"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🌐 Sprachen"])},
    "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🧰 Toolbox"])}
  },
  "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "title": {
    "calc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechne das Ergebnis!"])},
    "generator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Emoji-basierte Umfragen!"])}
  },
  "wip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🧑‍💻 Die App ist noch in Arbeit."])},
  "settings": {
    "regenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["♻️ Regenerieren Sie alle Emoji"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⚙️ Einstellungen"])}
  },
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nah dran"])},
  "input": {
    "ph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Lieblingsumfrage 🗳"])},
    "ph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h18🌅,h21🌃"])}
  }
}